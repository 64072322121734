<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit">{{ $t('Bank payments') }}</div>
<!--            <AddNewButton :label="$t('Add')" @click="addNewItem" />-->
          </template>
          <template #end>
            <div class="p-d-flex p-ai-center p-mr-2" v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
              <!--            <InputSwitch id="form-agree-inputswitch" class="p-mr-2" v-model="privatePolicyIsAccepted"/>-->
              <Checkbox :disabled="dataTableIsLoading" @change="getItems" id="bank-payments-show-only-unpaid-inputswitch" :binary="true" v-model="showOnlyUnpaid"/>
              <div>
                <label for="bank-payments-show-only-unpaid-inputswitch" >
                  <div class="pointer p-p-2">Show only unpaid</div>
                </label>
              </div>
            </div>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :tableName="$t('Bank payments')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
              {name: 'sales_invoice_id', title: 'Invoice', sortable: true},
              {name: 'order_id', title: 'Order', sortable: true},
              {name: 'customer', title: 'Customer', sortable: false},
              {name: 'date', title: 'Date', sortable: true},
              {name: 'payment_due', title: 'Payment due', sortable: false},
              {name: 'overdue', sortable: false},
              {name: 'state', title: 'State', sortable: false},
              {name: 'sum', title: 'Sum', sortable: true},
              {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'updated-item': isItemUpdated(item.id)}">
              <td>
                <router-link v-if="item.sales_invoice" :to="{ path: '/sales-invoices', query: { search: item.sales_invoice.number } }" target="_blank">
                  <span>{{ item.sales_invoice.number }}</span>
                </router-link>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <router-link v-if="item.order" :to="{ path: `/orders/${item.order.number}` }" target="_blank">{{ item.order.number }}</router-link>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <router-link v-if="item.customer" :to="{ path: `/customers/${item.customer.id}` }" target="_blank">
                  <span v-if="item.customer.type === constants.userTypes.legal_entity && item.customer.company_name">{{ item.customer.company_name }}</span>
                  <span v-else>{{ item.customer.first_name }} {{ item.customer.last_name }}</span>
                </router-link>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                {{ formatDate(item.date) }}
              </td>
              <td>
                <span v-if="item.sales_invoice" :class="{'warning-color': (countOverdueDays(item.sales_invoice.payment_due) <= 0) && item.state !== 3}">{{ formatDay(item.sales_invoice.payment_due) }}</span>
              </td>
              <td>
                <span v-if="item.sales_invoice && item.state !== 3" :class="{'warning-color': countOverdueDays(item.sales_invoice.payment_due) <= 0}">{{ countOverdueDays(item.sales_invoice.payment_due) }}</span>
              </td>
              <td>
                <div  class="p-d-flex p-flex-wrap p-ai-center">
                  <div v-if="item.state" class="state-badge" :class="PaymentStates.find(state => state.num === item.state)?.color || ''">{{ PaymentStates.find(state => state.num === item.state)?.label[$i18n.locale] || '' }}</div>
                </div>
              </td>
              <td>
                <span>{{ formatCurrency(item.sum) }}</span>
              </td>
              <td class="table-date-line">
                <span v-if="item.created_at">
                  {{ formatDate(item.created_at) }},
                </span>
                <span v-if="item.creator" class="table-date-line">
                  {{ item.creator.first_name }} {{ item.creator.last_name }}
                </span>
              </td>
              <td>
                <div class="p-d-flex">
                  <AcceptButton :disabled="dataTableIsLoading" v-if="item.state === 1" @click="changePaymentState(item)"/>
                  <RemoveButton :disabled="dataTableIsLoading" v-if="item.state === 3" @click="changePaymentState(item)"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <!--  Mobile DataTable -->
<!--        <MobileDataTable v-else-if="!isLoading && $store.state.tabletLayout"-->
<!--                         :tableName="$t('Working areas')"-->
<!--                         :showSearchInput="false"-->
<!--                         :itemsArrayIsEmpty="itemsArrayIsEmpty">-->
<!--          <template v-slot:body>-->
<!--            <tr :class="{'updated-item': isItemUpdated(item.id)}"-->
<!--                v-for="(item, index) of items"-->
<!--                :key="index">-->
<!--              <td>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center p-pt-2">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Name') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span>-->
<!--                      <div>{{ item.name  || '' }}</div>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Description:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ item.description || '' }}</div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Sorting:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ item.sorting || '' }}</div>-->
<!--                </div>-->
<!--                <div class="p-m-2 p-d-flex p-jc-center">-->
<!--                  <EditButton @click="editItem(item)" />-->
<!--                  <DeleteButton @click="confirmItemDelete({id: item.id, first_name: item.first_name, last_name: item.last_name,role: item.role,company_name: item.company_name ? item.company_name : null})"-->
<!--                                v-show="$store.state.user.role === 0 || $store.state.user.role === 1"/>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </template>-->
<!--        </MobileDataTable>-->

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>


<!--        <AreaModal :visible="itemModal"-->
<!--                   :item="item"-->
<!--                   @update-items="updateItems"-->
<!--                   @close="closeItemModal">-->
<!--        </AreaModal>-->

<!--        <ConfirmDeleteModal-->
<!--            :visible="confirmItemDeleteModal"-->
<!--            @close="closeConfirmItemDeleteModal"-->
<!--            @delete-item="deleteItem"-->
<!--            :data="itemToDeleteData">-->
<!--        </ConfirmDeleteModal>-->
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import PaymentStates from '@/translations/states/PaymentStates'
import formatMixins from '@/mixins/formatMixins'
import RemoveButton from '@/components/DataTable/RemoveButton'
import AcceptButton from '@/components/DataTable/AcceptButton'
import calculateMixins from '@/mixins/calculateMixins'
import datatableMixins from "@/mixins/datatableMixins";
import settings from "@/settings";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ formatMixins, calculateMixins, datatableMixins, showErrorsMixins ],
  components: {  RemoveButton, AcceptButton },
	data() {
		return {
      showOnlyUnpaid: false,
      // isLoading: true,
      fieldsExpand: 'order, sales_invoice, customer',
      apiLink: 'sales-invoice-payment/get-bank-payments',
      routePath: '/bank-payments',
      // searchData: '',
      sort: {
        column: 'sales_invoice_id',
        direction: '-',
      },
      // defaultSort: {
      //   column: 'sales_invoice_id',
      //   direction: '-',
      // },
			// items: [],
			// item: {},
      // updatedItem: null,
      // itemToDelete: {},
			// submitted: false,
      // pagination: {
      //   totalCount: null,
      //   pageCount: null,
      //   currentPage: 1,
      //   perPage: 25
      // },
      PaymentStates,
      // constants
		}
	},
  // watch: {
  //   '$route'() {
  //     this.checkRouteQuery()
  //     if (this.$route.path === '/login' || this.$route.path !== '/bank-payments') {
  //       return false
  //     }
  //     this.getItems()
  //   },
  // },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    // this.sort.column = this.defaultSort.column
    // this.sort.direction = this.defaultSort.direction

    this.checkRouteQuery()
    this.getItems()
	},
  // unmounted() {
  //   this.items = []
  // },
  methods: {
    async changePaymentState(payment) {
      const paymentObj = {
        paymentId: payment.id,
        newState: payment.state === 1 ? 3 : 1,
      }
      try {
        const { status } = await httpClient.post('sales-invoice-payment/change-state', paymentObj)
        if (status === 200) {
          payment.state = paymentObj.newState
          this.updatedItem = payment.id
        }
      } catch(err) {
        this.showError(err)
      }
    },
    // searchItems(searchData) {
    //   this.searchData = searchData
    //   this.$router.push({
    //     route: this.$route.path,
    //     query: {
    //       ...this.$route.query,
    //       search: this.searchData,
    //     }
    //   })
    // },
    // updateItems(area, clearItem = false) {
    //   if (area) {
    //     this.updatedItem = area.id
    //   }
    //   if (clearItem) {
    //     this.item = {}
    //     this.submitted = false
    //   }
    //
    //   this.getItems()
    // },
    // checkRouteQuery() {
    //   if (this.$route.query.page) {
    //     this.pagination.currentPage = +this.$route.query.page
    //   } else {
    //     this.pagination.currentPage = 1
    //   }
    //   if (this.$route.query.perPage) {
    //     this.pagination.perPage = +this.$route.query.perPage
    //   } else {
    //     this.pagination.perPage = 25
    //   }
    //   if (this.$route.query.sort) {
    //     const firstSign = this.$route.query.sort[0]
    //     if (firstSign === '-') {
    //       this.sort.direction = '-'
    //       let sortColumn = this.$route.query.sort.split('')
    //       this.sort.column = sortColumn.splice(1).join('')
    //     } else {
    //       this.sort.direction = ''
    //       this.sort.column = this.$route.query.sort
    //     }
    //   }
    //
    //   if (this.$route.query.search) {
    //     this.searchData = this.$route.query.search
    //   } else {
    //     this.searchData = ''
    //   }
    // },
    // sortItems(columnName) {
    //   if (this.sort.column !== columnName) {
    //     this.sort.direction = ''
    //   } else {
    //     this.sort.direction = this.sort.direction === '' ? '-' : '';
    //   }
    //   this.sort.column = columnName
    //
    //   this.$router.push({
    //     route: this.$route.path,
    //     query: {
    //       ...this.$route.query,
    //       sort: this.sort.direction + this.sort.column
    //     }
    //   })
    // },
    // isItemUpdated(itemId) {
    //   return this.updatedItem === itemId
    // },
    async getItems() {
      this.dataTableIsLoading = true
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient(`${this.apiLink}`, {
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
            show_only_unpaid: +this.showOnlyUnpaid,
          }
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
      }
    },
	},
  computed: {
    // itemsArrayIsEmpty() {
    //     return !(this.items && this.items.length)
    // },
    actionButtonsCount() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin) {
        return 1
      } else {
        return 1
      }
    },
    // itemToDeleteData() {
    //   return 'delete data'
    // },
  }
}
</script>

<style scoped lang="scss">

</style>